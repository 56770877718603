
import React, { useLayoutEffect, useRef } from "react"
import Helmet from "react-helmet"
import Layout from "../components/main/layout";
import Seo from "../components/main/seo";
// import { StaticQuery, graphql } from 'gatsby';
import { graphql, Link, withPrefix } from 'gatsby';
import { translaterUtil } from "../helpers/translateUtils"
import Closing from "../components/shared/closing";
import IncludeGTM from "../helpers/gtm";
import DgoClientDataCookie from "../helpers/dgo-cookie";
import { dateTransform } from "../helpers/dateUtils";
import MicroInteractionsInit from "../helpers/animations/micro-interactions";


const Blog = ({ pageContext, data }) => {
  IncludeGTM()
  DgoClientDataCookie()


  // ! MICRO INTERACTIONS: Start

  const miRefMap = useRef(new Map());

  useLayoutEffect(() => {

    // Find all DOM nodes with the "my-class" class name
    const miNodes = document.querySelectorAll('.js-transition');

    // Map each node to a new ref object and store it in the refMap
    miNodes.forEach((node) => {
      miRefMap.current.set(node, React.createRef());
    });

  }, []);

  MicroInteractionsInit(miRefMap)



  // ! UTILS

  const translation = (e) => translaterUtil(pageContext.lang, pageContext.template)[e]


  // ! STRAPI

  const $alternatePages = data.allSitePage.nodes.filter(e => e.pageContext.template == pageContext.template);

  const $metadata = data.strapiMetadata;
  const $mainNav = data.strapiMainNavigation;
  const $loginNav = data.strapiLoginNavigation;
  const $footerData = data.strapiFooterNavigation;
  const $socialsData = data.strapiFooterNavigation.Socials.socials_list;
  const $closingData = data.strapiClosing;

  /* Single type */

  const $blogData = data.strapiBlog;
  const $blogMetadata = $blogData.Metadata

  const $blogCategories = data.allStrapiBlogCategory.nodes;
  const $blogPosts = data.allStrapiBlogPost.nodes;


  return (


    <Layout mainNav={$mainNav} loginNav={$loginNav} footerData={$footerData} lang={pageContext.lang} alternatePages={$alternatePages} socialData={$socialsData}>

      <Seo metadata={$metadata} pageMetadata={$blogMetadata} alternatePages={$alternatePages} activeLang={pageContext.lang} isPreview={true} />

      <Helmet>
        <link href={withPrefix('assets/styles/blog-hero.inline.min.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('assets/styles/blog-nav.inline.min.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('assets/styles/blog.min.css')} rel="stylesheet" type="text/css" />

      </Helmet>

      {/* ! HERO */}
      <div className="c-hero">
        <div className="c-hero__wrapper o-wrapper">
          <div className="c-hero__info o-section">
            <h1 className="c-hero__heading c-heading">
              {$blogData.title}
            </h1>
            <p className="c-hero__text u-text">
              {$blogData.description}
            </p>
          </div> {/* .c-hero__info */}
          <figure className="c-hero__graphic">
            <img src={$blogData.image.url} width={$blogData.image.width} height={$blogData.image.height} alt="" className="c-hero__image" />
          </figure>
        </div> {/* .c-hero__wrapper */}
      </div> {/* .c-hero */}
      <main>


        {/* ! BLOG NAV*/}
        <nav className="c-blog-nav u-text">
          <div className="c-blog-nav__wrapper o-wrapper">
            <div className="c-blog-nav__holder">
              <h2 className="c-blog-nav__title">{translation(1)}</h2>
              <ul className="c-blog-nav__list">
                <li className="c-blog-nav__item">
                  <span className="c-blog-nav__link is-active">
                    {translation(0)}
                  </span>
                </li>

                {$blogCategories.map((category, j) => {

                  return (
                    <li key={j} className="c-blog-nav__item">
                      <a href={`/${pageContext.lang}/blog/categories/${category.slug}`} className="c-blog-nav__link">
                        {category.name}
                      </a>
                    </li>
                  )
                })}
              </ul> {/* .c-blog-nav__list */}
            </div> {/* .c-blog-nav__holder */}
          </div> {/* .c-blog-nav__wrapper */}
        </nav> {/* .c-blog-nav */}



        {/* ! NEWS LIST */}
        <ul className="c-blog o-section o-wrapper" data-highlights="true">

          {$blogPosts.slice(0).reverse().map((post, j) => {

            let postLink = `/${pageContext.lang}/blog/${post.slug}`;
            let postCategory = `/${pageContext.lang}/blog/categories/${post.blog_category.slug}`;

            return (
                
              <li className={`c-blog__item ${j <= 1 ? 'c-blog__item--highlight' : ''}`}>
                <article className="c-news">
                  <a href={postLink} className="c-news__img-link" tabIndex={-1} title={post.title}>
                    <picture className="c-news__pic">
                      <img src={post.image.url} width={post.image.width} height={post.image.height} alt="" />
                    </picture>
                  </a>
                  <div className="c-news__holder u-text">
                    <div className="c-news__text">
                      <a href={postCategory} className="c-news__category">{post.blog_category.name}</a>
                      <span className="c-news__date">
                        <time>{dateTransform(post.date, pageContext.lang)} </time>
                        • {post.read_time}
                      </span>
                    </div> {/* .c-news__text */}
                    <h2 className="c-news__title">
                      <a href={postLink} tabIndex={-1}>
                        {post.title}
                      </a>
                    </h2>
                    <a href={postLink} className="c-news__link c-news-link u-tap-size">
                      <svg className="c-news-link__arrow" fill="none" height={12} viewBox="0 0 14 12" width={14} xmlns="http://www.w3.org/2000/svg"><path d="m.423352 6.61914 12.131148.00001-3.93421 4.25935c-.16698.1771-.16966.4673-.00605.648.16367.1808.43168.1838.59865.0066.00201-.0022.00403-.0043.00605-.0066l4.65686-5.04169c.1654-.17881.1656-.46874.0005-.64778l-4.65736-5.042001c-.16698-.177136-.43498-.174226-.5986.006546-.16134.1782-.16134.463375 0 .641575l3.93416 4.25932h-12.131148c-.233795 0-.42335234.20522-.42335236.45834-.00000002.25311.18955736.45833.42335236.45833z" /></svg>
                      {translation(2)}
                    </a>
                  </div> {/* .c-news__holder */}
                </article>
              </li>

            )
          })}

        </ul> {/* .c-blog */}
      </main>

      {/* ! CLOSING */}

      <Closing
        heading={$closingData.title}
        text={$closingData.description}
        cta={$closingData.Link}
        ctaUrl={$closingData.Link.url}
        ctaText={$closingData.Link.title}
      />

    </Layout>

  )
}



export const query = graphql`
  query BlogPageQuery ($lang: String)  {

  strapiMetadata (locale: {eq: $lang}) {
    title
    description
    avatar{
        url
    }
    
  },
  strapiMainNavigation (locale: {eq: $lang}) {
    links {
      title
      url
    }
  },
  strapiLoginNavigation (locale: {eq: $lang}) {
    links {
      url
      title
    }
  },
  strapiFooterNavigation (locale: {eq: $lang}) {
    Socials {
      title
      socials_list{
        slug
        link{
          title
          url
        }
        image{
          url
          width
          height
        }
      }
    }
    Disclaimer {
      links {
        url
        title
      }
    }
    Link_group {
      link {
        title
        url
      }
    }
  },
  strapiClosing (locale: {eq: $lang}) {
    title
    description
    Link{
      title
      url
    }
  },
  strapiBlog (locale: {eq: $lang}) {
    Metadata {
      title
      keywords
      avatar {
        url
      }
    }
    image {
      url
      width
      height
    }
    description
    title
  },
  allSitePage {
      nodes {
        path
        pageContext
      }
  },
  allStrapiBlogCategory(filter: {locale: {eq: $lang}}) {
    nodes {
      name
      slug
    }
  }
  allStrapiBlogPost(filter: {locale: {eq: $lang}}) {
    nodes {
      blog_category {
        name
        slug
      }
      date
      image {
        url
        width
        height
      }
      title
      slug
      read_time
    }
  }

}
`

export default Blog
